import Swal from 'sweetalert2';

const loginForm = document.getElementById('loginForm');

export function checkPassword() {
  if (!loginForm) {
    return;
  }

  loginForm.addEventListener('submit', async evt => {
    const password = document.getElementById('password').value;
    if (password.length < 9) {
      evt.preventDefault();

      await Swal.fire('Passwortsicherheit', 'Das von Ihnen verwendete Passwort ist sehr unsicher. Bitte ändern ' +
          'Sie dieses in Ihrem Profil zu Ihrer eigenen Sicherheit!', 'warning');

      loginForm.submit();
    }
  });
}
